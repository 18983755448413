import { Suspense, lazy, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Redirect, Route, Switch } from 'wouter';
import { useCspReport } from '@medifind/hooks';
import { NotificationCenter, Popups } from '@medifind/popups';
// import { CheckPrerenderRedirect, createBrowserHistory, isPrerender } from '@medifind/router';
import { CheckPrerenderRedirect, LocationConfirmationDialog, Router, RouterSettings } from '@medifind/router';
import { MFRoute } from '@medifind/router';
import { RageClickCircles, RageClicks, ScrollToTop } from '@medifind/shared-basic-components';
import { ProgressBar } from '@medifind/shared-basic-components';
import { ErrorBoundary } from '@medifind/shared-basic-components';
import { MFLazyLoad } from '@medifind/shared-basic-components';
import { getValueMediaBreakpointDown, isPrerender } from '@medifind/utils';
import { registerServiceWorker } from '@medifind/utils';
import {
  authenticate,
  getFeatureFlag,
  initTagManager,
  registerBroadcastAuthentication,
  registerDeauthHandler,
  setFeatureFlag,
  useAppVersionPoll,
  useAuthentication,
} from '@medifind/zustand';
import Header from './components/Header';
import Layout from './components/Layout';
import PageHead from './components/PageHead';
import { commonRoutes, routes } from './routes';
// const history = createBrowserHistory();
const Footer = lazy(() => import('./components/Footer'));
const ErrorPage = lazy(() => import('./pages/error'));
const SudoUserBanner = lazy(() => import('./components/profile-management/admin/users/SudoUserBanner.js'));

const AppRouter = () => {
  const queryClient = new QueryClient();
  const decodedToken = useAuthentication((state) => state?.decodedToken);
  const isSudoUser = decodedToken?.isSudoLogin;
  useCspReport({ disable: ['LOCAL'].includes(process.env.NX_ENV) });
  useAppVersionPoll({ disable: ['LOCAL'].includes(process.env.NX_ENV) });

  const [uiError, setUiError] = useState(false);

  useEffect(() => {
    registerDeauthHandler(() => import('./zustand/clearStores').then((mod) => mod.default()));

    if (!isPrerender) {
      registerServiceWorker();
      authenticate();
      initTagManager({
        originalLocation:
          document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search,
      });
    }
    window.setFeatureFlag = (name, on = true) => {
      setFeatureFlag(name, on);
    };
    window.getFeatureFlag = (name) => getFeatureFlag(name);
  }, []);
  useEffect(() => {
    if (!isPrerender) {
      return registerBroadcastAuthentication();
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
      <PageHead />
      <RageClicks />
      <Router>
        <Layout>
          <RageClickCircles />
          <MFRoute
            path="/:rest*"
            pathExclude={uiError ? [] : ['^/[^/]+-doctor-finder([/?]|$)', '^/[^/]+-infusion-finder([/?]|$)']}
            component={Header}
            passRouteStoreParams={false}
          />
          <div style={{ minHeight: '100vh', position: 'relative' }}>
            <ErrorBoundary onUiError={setUiError} errorPage={ErrorPage}>
              <ScrollToTop />
              <ProgressBar />
              <Popups />
              <CheckPrerenderRedirect />
              <LocationConfirmationDialog />
              <NotificationCenter />
              <Suspense fallback={<div className="fallback" />}>
                <Switch>
                  {commonRoutes.map(({ path, component, render }) => (
                    <MFRoute key={path} path={path} component={component} render={render} passRouteStoreParams={false} />
                  ))}
                </Switch>
              </Suspense>
              <Suspense fallback={<div className="fallback" />}>
                <Routes />
                <MFRoute
                  path="/:rest*"
                  render={() => (
                    <MFLazyLoad height={getValueMediaBreakpointDown({ xs: 1200, default: 700 })} resize>
                      <Footer
                        smallFooterPaths={['^/[^/]+-doctor-finder([/?]|$)', 'email-unsubscribe', '^/[^/]+-infusion-finder([/?]|$)']}
                      />
                    </MFLazyLoad>
                  )}
                  passRouteStoreParams={false}
                  suspense
                />
              </Suspense>
              <Suspense fallback={<div className="fallback" />}>{isSudoUser && <SudoUserBanner />}</Suspense>
            </ErrorBoundary>
          </div>
        </Layout>
      </Router>
      <RouterSettings />
      <div id="mf-root-popup"></div>
      </QueryClientProvider>
    </>
  );
};
export default AppRouter;

const Routes = () => {
  const redirects = [
    {
      path: '/(conditions|procedures)/:codingSlug/:projectId/details',
      href: window.location.pathname.replace('/details', '') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/treatment-advances',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/publications',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/clinical-trials',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/clinical-trial/:articleId',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/treatment-advance/:articleId',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    {
      path: '/experts/:personSlug/:personId/publication/:articleId',
      href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search,
    },
    { path: '/experts/:personSlug/:personId', href: window.location.pathname.replace('/experts/', '/doctors/') + window.location.search },
    { path: '/trial-search', href: window.location.pathname.replace('/trial-search', '/clinical-trials') + window.location.search },
    { path: '/best-urologist-near-me', href: '/specialty/urologist' + window.location.search },

    {
      path: '/doctor-finder/gastroenterologist/(ulcerative-colitis|crohns-disease)',
      href: window.location.pathname.replace('/doctor-finder', '/entyvio-doctor-finder') + window.location.search,
    },
    {
      path: '/drug/:drugId',
      href: window.location.pathname.replace('/drug/', '/drugs/') + window.location.search,
    },
  ];
  return (
    <Switch>
      {routes.map(({ path, component, render }) => (
        <MFRoute key={path} path={path} component={component} render={render} mainRoute />
      ))}
      {redirects.map(({ path, href }) => (
        <Route key={path} path={path}>
          <Redirect href={href} />
        </Route>
      ))}

      <MFRoute
        path="/:rest*"
        render={() => {
          throw new Error(404);
        }}
      />
    </Switch>
  );
};
